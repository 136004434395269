<template>
  <section class="privacy-policy">
    <base-container>
      <template v-for="content in cmsContents" :key="content.id">
        <template v-if="content.slug === 'page-privacy-policy'">
          <h1>{{ content.title }}</h1>
          <div v-html="content.content"></div>
        </template>
      </template>
    </base-container>
  </section>
</template>

<script>
export default {
  computed: {
    cmsContents() {
      return this.$store.getters["dashboard/cmsContents"];
    },
  },
  created() {
    this.$store.dispatch("dashboard/getCmsContent");
  },
};
</script>

<style scoped>
.privacy-policy h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #141414;
  margin: 1rem 0;
}
</style>