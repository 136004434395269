<template>
  <section class="e-wallet">
    <base-card>
      <h3>E-Wallet</h3>
      <p>HK$ {{ currentUserDetails.eWallet }}</p>
      <el-button>Top up</el-button>
    </base-card>
  </section>
</template>

<script>
export default {
  computed: {
    currentUserDetails() {
      return this.$store.getters["auth/currentUserDetails"];
    },
  },
};
</script>

<style scoped>
.e-wallet h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #141414;
}

.e-wallet p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #141414;
  margin: 1rem 0;
}

.e-wallet .el-button {
  background: #fe5d1f;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-color: #fe5d1f;
  padding: 1.2rem;
}
</style>