<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  margin-top: 1rem;
}
</style>